import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { I18nComponent, LocaleService, TranslationStore } from "@smallstack/i18n-components";
import { IconComponent } from "@smallstack/theme-components";
import { Subscription } from "rxjs";
import {
  MatPasswordStrengthComponent,
  MatPasswordStrengthInfoComponent,
  MatPasswordStrengthModule
} from "../password-strength/mat-password-strength.module";

@Component({
  selector: "smallstack-user-password-field",
  templateUrl: "./password-field.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, MatPasswordStrengthModule, I18nComponent, IconComponent]
})
export class PasswordFieldComponent implements AfterViewInit, OnDestroy {
  @HostBinding("@.disabled")
  public disabled = true;

  @ViewChild(MatPasswordStrengthInfoComponent, { static: false })
  public passwordStrengthInfo: MatPasswordStrengthInfoComponent;

  @ViewChild(MatPasswordStrengthComponent, { static: false })
  public passwordComponent: MatPasswordStrengthComponent;

  @Input("password")
  public passwordValue: string;

  @Input()
  public label: string = "@@components.passwordfield.password.label";

  @Input()
  public hint: string;

  @Input()
  public showLabel = true;

  @Input()
  public placeholder: string;

  @Output()
  public readonly passwordChange: EventEmitter<string> = new EventEmitter();

  public hidePassword = true;

  private subscription = new Subscription();

  constructor(
    private cdr: ChangeDetectorRef,
    private localeService: LocaleService,
    private translationStore: TranslationStore
  ) {}

  public ngAfterViewInit(): void {
    this.subscription.add(
      this.localeService.currentLocale$.subscribe(async () => {
        this.applyMessages();
        this.cdr.markForCheck();
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onStrengthChanged(): void {
    this.passwordStrengthInfo.onPasswordStrengthChange();
  }

  public passwordChanged(newValue: string): void {
    this.passwordComponent.password = newValue;
    this.passwordComponent.calculatePasswordStrength();
    if (this.passwordComponent.strength >= 100) this.passwordChange.emit(this.passwordValue);
  }

  private applyMessages() {
    const lowercase = this.translationStore.translate("@@components.registration.messages.lowercase");
    if (lowercase) this.passwordStrengthInfo.lowerCaseCriteriaMsg = lowercase;
    const minchars = this.translationStore.translate("@@components.registration.messages.minchars", {
      replacers: {
        min: "" + this.passwordComponent.min
      }
    });
    if (minchars) this.passwordStrengthInfo.minCharsCriteriaMsg = minchars;
    const specialchar = this.translationStore.translate("@@components.registration.messages.specialchar");
    if (specialchar) this.passwordStrengthInfo.specialCharsCriteriaMsg = specialchar;
    const uppercase = this.translationStore.translate("@@components.registration.messages.uppercase");
    if (uppercase) this.passwordStrengthInfo.upperCaseCriteriaMsg = uppercase;
    const digit = this.translationStore.translate("@@components.registration.messages.digit");
    if (digit) this.passwordStrengthInfo.digitsCriteriaMsg = digit;
    this.passwordStrengthInfo.onPasswordStrengthChange();
  }
}
