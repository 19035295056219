import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from "@angular/core";
import { UntypedFormControl, ValidatorFn, Validators } from "@angular/forms";
import { Criteria } from "../../enum/criteria.enum";
import { MatPasswordStrengthValidator } from "../../validator/mat-password-strength-validator";
import { RegExpValidator } from "../../validator/regexp.class";

@Component({
  selector: "mat-password-strength",
  exportAs: "matPasswordStrength",
  templateUrl: "./mat-password-strength.component.html",
  styleUrls: ["./mat-password-strength.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatPasswordStrengthComponent implements OnInit, OnChanges {
  @Input() public password: string;
  @Input() public externalError: boolean;
  @Input() public enableLengthRule = true;
  @Input() public enableLowerCaseLetterRule = true;
  @Input() public enableUpperCaseLetterRule = true;
  @Input() public enableDigitRule = true;
  @Input() public enableSpecialCharRule = true;
  @Input() public min = 8;
  @Input() public max = 30;
  @Input() public customValidator: RegExp;
  @Input() public warnThreshold = 21;
  @Input() public accentThreshold = 81;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public readonly onStrengthChanged: EventEmitter<number> = new EventEmitter();

  public criteriaMap = new Map<Criteria, RegExp>();

  public containAtLeastMinChars: boolean;
  public containAtLeastOneLowerCaseLetter: boolean;
  public containAtLeastOneUpperCaseLetter: boolean;
  public containAtLeastOneDigit: boolean;
  public containAtLeastOneSpecialChar: boolean;
  public containAtCustomChars: boolean;

  // TO ACCESS VIA CONTENT CHILD
  public passwordFormControl: UntypedFormControl = new UntypedFormControl();
  public passwordConfirmationFormControl: UntypedFormControl = new UntypedFormControl();

  public validatorsArray: ValidatorFn[] = [];

  private _strength = 0;

  public Validators: ValidatorFn;
  public matPasswordStrengthValidator = new MatPasswordStrengthValidator();

  public ngOnInit(): void {
    this.setRulesAndValidators();

    if (this.password) {
      this.calculatePasswordStrength();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ((changes.externalError && changes.externalError.firstChange) || changes.password.isFirstChange()) {
      return;
    } else if (changes.externalError && changes.externalError.currentValue) {
      return;
    } else if (changes.password.previousValue === changes.password.currentValue && !changes.password.firstChange) {
      this.checkPassword();
    } else {
      this.password && this.password.length > 0 ? this.checkPassword() : this.reset();
    }
  }

  public get strength(): number {
    return this._strength ? this._strength : 0;
  }

  public get color(): string {
    if (this._strength < this.warnThreshold) {
      return "progress-error";
    } else if (this._strength < this.accentThreshold) {
      return "progress-warning";
    } else {
      return "progress-success";
    }
  }

  private _containAtLeastMinChars(): boolean {
    this.containAtLeastMinChars = this.password.length >= this.min;
    return this.containAtLeastMinChars;
  }

  private _containAtLeastOneLowerCaseLetter(): boolean {
    this.containAtLeastOneLowerCaseLetter = this.criteriaMap
      .get(Criteria.at_least_one_lower_case_char)
      .test(this.password);
    return this.containAtLeastOneLowerCaseLetter;
  }

  private _containAtLeastOneUpperCaseLetter(): boolean {
    this.containAtLeastOneUpperCaseLetter = this.criteriaMap
      .get(Criteria.at_least_one_upper_case_char)
      .test(this.password);
    return this.containAtLeastOneUpperCaseLetter;
  }

  private _containAtLeastOneDigit(): boolean {
    this.containAtLeastOneDigit = this.criteriaMap.get(Criteria.at_least_one_digit_char).test(this.password);
    return this.containAtLeastOneDigit;
  }

  private _containAtLeastOneSpecialChar(): boolean {
    this.containAtLeastOneSpecialChar = this.criteriaMap.get(Criteria.at_least_one_special_char).test(this.password);
    return this.containAtLeastOneSpecialChar;
  }

  private _containCustomChars(): boolean {
    this.containAtCustomChars = this.criteriaMap.get(Criteria.at_custom_chars).test(this.password);
    return this.containAtCustomChars;
  }

  private checkPassword(): void {
    this.calculatePasswordStrength();
    this.passwordConfirmationFormControl.updateValueAndValidity();
  }

  public parseCustomValidatorsRegex(value: string | RegExp = this.customValidator): RegExp {
    if (this.customValidator instanceof RegExp) {
      return this.customValidator;
    } else if (typeof this.customValidator === "string") {
      return RegExp(this.customValidator);
    }
  }

  public setRulesAndValidators(): void {
    this.validatorsArray = [];
    this.criteriaMap = new Map<Criteria, RegExp>();
    this.passwordConfirmationFormControl.setValidators(
      Validators.compose([Validators.required, this.matPasswordStrengthValidator.confirm(this.password)])
    );
    this.validatorsArray.push(Validators.required);
    if (this.enableLengthRule) {
      this.criteriaMap.set(Criteria.at_least_eight_chars, RegExp(`^.{${this.min},${this.max}}$`));
      this.validatorsArray.push(Validators.minLength(this.min));
      this.validatorsArray.push(Validators.maxLength(this.max));
    }
    if (this.enableLowerCaseLetterRule) {
      this.criteriaMap.set(Criteria.at_least_one_lower_case_char, RegExpValidator.lowerCase);
      this.validatorsArray.push(Validators.pattern(RegExpValidator.lowerCase));
    }
    if (this.enableUpperCaseLetterRule) {
      this.criteriaMap.set(Criteria.at_least_one_upper_case_char, RegExpValidator.upperCase);
      this.validatorsArray.push(Validators.pattern(RegExpValidator.upperCase));
    }
    if (this.enableDigitRule) {
      this.criteriaMap.set(Criteria.at_least_one_digit_char, RegExpValidator.digit);
      this.validatorsArray.push(Validators.pattern(RegExpValidator.digit));
    }
    if (this.enableSpecialCharRule) {
      this.criteriaMap.set(Criteria.at_least_one_special_char, RegExpValidator.specialChar);
      this.validatorsArray.push(Validators.pattern(RegExpValidator.specialChar));
    }
    if (this.customValidator) {
      this.criteriaMap.set(Criteria.at_custom_chars, this.parseCustomValidatorsRegex());
      this.validatorsArray.push(Validators.pattern(this.parseCustomValidatorsRegex()));
    }

    this.criteriaMap.forEach((value: any, key: string) => {
      this.validatorsArray.push(this.matPasswordStrengthValidator.validate(key, value));
    });

    this.passwordFormControl.setValidators(Validators.compose([...this.validatorsArray]));
    this.Validators = Validators.compose([...this.validatorsArray]);
  }

  public calculatePasswordStrength(): void {
    const requirements: Array<boolean> = [];
    const unit = 100 / this.criteriaMap.size;

    // console.log('this.criteriaMap.size = ', this.criteriaMap.size);
    // console.log('unit = ', unit);

    requirements.push(
      this.enableLengthRule ? this._containAtLeastMinChars() : false,
      this.enableLowerCaseLetterRule ? this._containAtLeastOneLowerCaseLetter() : false,
      this.enableUpperCaseLetterRule ? this._containAtLeastOneUpperCaseLetter() : false,
      this.enableDigitRule ? this._containAtLeastOneDigit() : false,
      this.enableSpecialCharRule ? this._containAtLeastOneSpecialChar() : false,
      this.customValidator ? this._containCustomChars() : false
    );

    this._strength = requirements.filter((v) => v).length * unit;
    // console.log('length = ', this._strength / unit);
    this.onStrengthChanged.emit(this.strength);
    this.setRulesAndValidators();
  }

  public reset(): void {
    this._strength = 0;
    this.containAtLeastMinChars =
      this.containAtLeastOneLowerCaseLetter =
      this.containAtLeastOneUpperCaseLetter =
      this.containAtLeastOneDigit =
      this.containAtCustomChars =
      this.containAtLeastOneSpecialChar =
        false;
  }
}
