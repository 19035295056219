import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { MatCardModule } from "@angular/material/card";
import { MatRippleModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { SmallstackThemeModule } from "@smallstack/theme-components";
import { MatPassToggleVisibilityComponent } from "./component/mat-pass-toggle-visibility/mat-pass-toggle-visibility.component";
import { MatPasswordStrengthInfoComponent } from "./component/mat-password-strength-info/mat-password-strength-info.component";
import { MatPasswordStrengthComponent } from "./component/mat-password-strength/mat-password-strength.component";
// Export module's public API
export { MatPassToggleVisibilityComponent } from "./component/mat-pass-toggle-visibility/mat-pass-toggle-visibility.component";
export { MatPasswordStrengthInfoComponent } from "./component/mat-password-strength-info/mat-password-strength-info.component";
export { MatPasswordStrengthComponent } from "./component/mat-password-strength/mat-password-strength.component";
export { MatPasswordStrengthValidator } from "./validator/mat-password-strength-validator";
// validator
export { RegExpValidator } from "./validator/regexp.class";

@NgModule({
  imports: [CommonModule, MatProgressBarModule, MatCardModule, MatIconModule, MatRippleModule, SmallstackThemeModule],
  exports: [MatPasswordStrengthComponent, MatPasswordStrengthInfoComponent, MatPassToggleVisibilityComponent],
  declarations: [MatPasswordStrengthComponent, MatPasswordStrengthInfoComponent, MatPassToggleVisibilityComponent]
})
export class MatPasswordStrengthModule {
  public static forRoot(): ModuleWithProviders<MatPasswordStrengthModule> {
    return {
      ngModule: MatPasswordStrengthModule,
      providers: []
    };
  }
}
