<div class="card card-bordered border-slate-300 card-compact bg-white">
  <div class="card-body">
    <div class="card-title"> Passwort Vorgaben </div>
    <div class="info-row" @items *ngIf="passwordComponent.enableLowerCaseLetterRule">
      <div *ngIf="passwordComponent.containAtLeastOneLowerCaseLetter; then done; else error" @flipY> </div>
      <ng-template #done>
        <div><i class="fa-regular fa-circle-check text-green-700"></i></div>
      </ng-template>
      <ng-template #error>
        <div><i class="fa-regular fa-circle-xmark text-red-700"></i></div>
      </ng-template>
      <span>{{ lowerCaseCriteriaMsg }}</span>
    </div>

    <div class="info-row" @items *ngIf="passwordComponent.enableUpperCaseLetterRule">
      <div *ngIf="passwordComponent.containAtLeastOneUpperCaseLetter; then done; else error"> </div>
      <ng-template #done>
        <div><i class="fa-regular fa-circle-check text-green-700"></i></div>
      </ng-template>
      <ng-template #error>
        <div><i class="fa-regular fa-circle-xmark text-red-700"></i></div>
      </ng-template>
      <span>{{ upperCaseCriteriaMsg }}</span>
    </div>

    <div class="info-row" @items *ngIf="passwordComponent.enableDigitRule">
      <div *ngIf="passwordComponent.containAtLeastOneDigit; then done; else error"> </div>
      <ng-template #done>
        <div><i class="fa-regular fa-circle-check text-green-700"></i></div>
      </ng-template>
      <ng-template #error>
        <div><i class="fa-regular fa-circle-xmark text-red-700"></i></div>
      </ng-template>
      <span>{{ digitsCriteriaMsg }}</span>
    </div>

    <div class="info-row" @items *ngIf="passwordComponent.enableSpecialCharRule">
      <div *ngIf="passwordComponent.containAtLeastOneSpecialChar; then done; else error"> </div>
      <ng-template #done>
        <div><i class="fa-regular fa-circle-check text-green-700"></i></div>
      </ng-template>
      <ng-template #error>
        <div><i class="fa-regular fa-circle-xmark text-red-700"></i></div>
      </ng-template>
      <span>{{ specialCharsCriteriaMsg }}</span>
    </div>

    <div class="info-row" @items *ngIf="passwordComponent.enableLengthRule">
      <div *ngIf="passwordComponent.containAtLeastMinChars; then done; else error"> </div>
      <ng-template #done>
        <div><i class="fa-regular fa-circle-check text-green-700"></i></div>
      </ng-template>
      <ng-template #error>
        <div><i class="fa-regular fa-circle-xmark text-red-700"></i></div>
      </ng-template>
      <span>{{ minCharsCriteriaMsg }}</span>
    </div>

    <div class="info-row" @items *ngIf="passwordComponent.customValidator">
      <div *ngIf="passwordComponent.containAtCustomChars; then done; else error"> </div>
      <ng-template #done>
        <div><i class="fa-regular fa-circle-check text-green-700"></i></div>
      </ng-template>
      <ng-template #error>
        <div><i class="fa-regular fa-circle-xmark text-red-700"></i></div>
      </ng-template>
      <span>{{ customCharsCriteriaMsg }}</span>
    </div>

    <div *ngIf="enableScoreInfo" class="info-row" @items>
      <div *ngIf="passwordComponent.strength === 100; then done; else error"> </div>
      <ng-template #done>
        <div><i class="fa-regular fa-circle-check text-green-700"></i></div>
      </ng-template>
      <ng-template #error>
        <div><i class="fa-regular fa-circle-xmark text-red-700"></i></div>
      </ng-template>
      <span>Password's strength = {{ passwordComponent.strength }} %100</span>
    </div>
  </div>
</div>
